<template>
    <div class="p-3">
        <div class="text-xl text-center font-weight-bold">{{article.title}}</div>
        <div class="text-info text-center">{{$moment.sdt(article.publishTime)}}</div>

        <div v-html="article.content" class="mt-3" style="line-height: 32px;"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                article: {},
            };
        },
        methods: {
            async readArticle() {
                let response = await this.$axios.post('/api/Article/ReadArticle', { id: this.id });
                this.article = response.data;
                document.title = this.article.title;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.readArticle();
        },
    };
</script>